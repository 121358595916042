import { useState, useEffect } from 'react';
import {
  Box, Stack, Typography, IconButton, Menu, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useAuth } from 'hooks';
import { teamDomain } from 'helpers';

const Button = styled((props) => (
  <IconButton size="medium" disableRipple {...props} />
))(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(0.6725),
    paddingBottom: theme.spacing(0.6725)
  },
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(0.5),
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(0.8915),
  paddingBottom: theme.spacing(0.8915),
  fontWeight: 600
}));

const TeamsMenu = () => {
  const { auth }                      = useAuth();
  const { t }                         = useTranslation();
  const navigate                      = useNavigate();
  const [anchor, setAnchor]           = useState(null);
  const [currentTeam, setCurrentTeam] = useState(auth.last_team);

  useEffect(() => setCurrentTeam(auth.last_team), [auth.last_team]);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const changeTeam = (team) => {
    if (!auth.last_team || auth.last_team.id !== team.id) {
      setCurrentTeam(team);
      navigate(`/team/${team.id}`);
    }
    handleCloseMenu();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
    >
      <Typography mr={2} color="text.secondary" sx={{ display: { xs: 'none', lg: 'block' } }}>
        {t('currentTeam')}
      </Typography>
      <Button onClick={handleOpenMenu}>
        <Typography
          component="span"
          className="ph-no-capture"
          variant="body1"
          color="black"
          fontWeight={600}
          mx={{ md: 1 }}
          sx={{ maxWidth: { sm: 180, md: 'none' } }}
          noWrap
        >
          {currentTeam ? teamDomain(currentTeam) : '-'}
        </Typography>
        <Typography color="text.secondary" textTransform="uppercase" fontWeight={600} ml={1}>{currentTeam.plan.name}</Typography>
        <ArrowDropDown color="inherit" />
      </Button>
      <Menu
        id="menu-teams"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
        sx={{ mt: 1 }}
      >
        {auth.teams.map((team) => (
          <MenuItem key={team.id} onClick={() => changeTeam(team)}>
            <Box width="100%" display="flex" justifyContent="space-between" className="ph-no-capture">
              {teamDomain(team)}
              <Typography color="text.secondary" textTransform="uppercase" fontWeight={600} ml={3}>{team.plan.name}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
export default TeamsMenu;
