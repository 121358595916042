import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';

const trackPages = [
  '/signup/*'
];

const useHubspot = () => {
  const { pathname }  = useLocation();

  useEffect(() => {
    if (trackPages.find((path) => matchPath({ path }, pathname))) {
      /* eslint-disable no-underscore-dangle, no-inner-declarations, prefer-rest-params */

      const hsScript = document.createElement('script');
      const gtmId = process.env.REACT_APP_GTM_ID;

      hsScript.setAttribute('src', process.env.REACT_APP_HS_SCRIPTS_URL);
      hsScript.setAttribute('id', 'hs-script-loader');

      document.body.appendChild(hsScript);

      window._hsp       = window._hsp || [];
      window.dataLayer  = window.dataLayer || [];

      window._hsp.push(['addPrivacyConsentListener', (consent) => {
        if (consent.categories.analytics) {
          const gtagScript = document.createElement('script');
          gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtmId}`);
          gtagScript.setAttribute('async', '');
          document.head.appendChild(gtagScript);
        }
      }]);

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', gtmId);

      /* eslint-enable no-underscore-dangle, no-inner-declarations, prefer-rest-params */
    }
  }, [pathname]);
};

export default useHubspot;
