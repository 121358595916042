import {
  Navigate, useLocation, Outlet, useOutletContext
} from 'react-router';
import { useAuth } from 'hooks';

const NonAuth = () => {
  const { auth }  = useAuth();
  const { state } = useLocation();
  const context   = useOutletContext();

  if (auth.id) {
    if (auth.created) {
      return <Navigate to="/thank-you" />;
    }
    return <Navigate to={state?.from || '/rooms'} />;
  }

  return <Outlet context={context} />;
};

export default NonAuth;
