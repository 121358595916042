export default {
  cameraAndMicBlocked: 'Your camera and mic are blocked. You need to allow access to perform this system check. Click <0>here</0> to learn how to enable you devices.',
  signallingServerCheck: 'Signalling server check',
  mediaServerCheck: 'Media server API check',
  connectionSuccessful: 'Connection to IP {{ ip }} on port {{ port }} was successful.',
  connectionFailed: 'Connection to IP {{ ip }} on port {{ port }} was failed.',
  connectingToServer: 'Connecting to {{ ip }} on port {{ port }}.',
  browserCheck: 'Browser check',
  browserCheckRecommended: 'You are using Google Chrome, which is the recommended browser for the best experience.',
  browserCheckSupported: 'Your browser is supported, but we recommend using Google Chrome for the best experience.',
  browserName: 'Browser name',
  browserVersion: 'Browser version',
  platform: 'Platform',
  userAgent: 'User agent',
  video: 'Video',
  audio: 'Audio',
  localVideo: 'Local video',
  videoFromServer: 'Your video from the server',
  recordingCountdown: 'Recording will be stopped in {{ countdown }} second',
  playingRecording: 'Playing recording...',
  playRecording: 'Play recording',
  testMicrophone: 'Test microphone',
  testMicrophoneInstructions: 'Speak and pause, you should hear you voice',
  testMicrophoneTooltip: 'If you don\'t hear your recording, please check your microphone',
  speakers: 'Speakers',
  defaultSpeakerDevice: 'System default speaker device',
  playing: 'Playing...',
  testSpeakers: 'Test speakers',
  testSpeakersInstructions: 'You should hear a rington',
  testSpeakersTooltip: 'If you don\'t hear the chime, please check your speakers',
  nextSteps: 'Important next steps',
  nextStepsDescription: 'Even if your test was successful, please take note of the following details, and then head over to our network configuration page, found <0>here</0>.',
  webServer: 'Web server',
  signalingServer: 'Signaling server',
  mediaServer: 'Media server',
  mediaServerApi: 'Media server API',
  mediaTraffic: 'Media traffic',
  mediaTrafficOptions: [
    'OPTION 1 (Best performance)',
    'OPTION 2 (Via a turn/relay server)'
  ],
  httpTrafficPort: 'Allow HTTPs traffic on port <0>{{ port }}</0>',
  wssTrafficPort: 'Secure websockets/wss traffic on port <0>{{ port }}</0>',
  mediaTrafficPort: 'HTTPs traffic on port <0>{{ port }}</0>',
  rtpTrafficPort: 'Allow RTP traffic TCP/UDP port',
  turnServer: 'Turn server',
  systemCheck: 'System check',
  systemCheckWelcome: 'Welcome to System check',
  systemCheckWelcomeMessage: 'On this page you can check if your system is ready to connect to the conference.',
  systemCheckWelcomeStart: 'Start System check',
  networkSettings: 'Network firewall or proxy server settings',
  networkSettingsNote: 'NOTE: All signalling and mediaserver endpoints can be whitelisted via allowing wildcard domain *.wbcnf.net if the client firewall allows such a configuration',
  ip: 'IP',
  ips: 'IPs',
  uri: 'URI'
};
