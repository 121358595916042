import { forwardRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { useAuth } from 'hooks';
import { logout } from 'queries/auth';

const LogoutLink = ({
  children, forwardedRef, onClick, ...props
}) => {
  const navigate      = useNavigate();
  const { resetAuth } = useAuth();
  const [
    { rememberMe }, , removeCookie
  ] = useCookies(['rememberMe']);

  const logoutQuery = useMutation({
    mutationFn: () => logout(rememberMe && {
      browser_id: rememberMe
    }),
    onSuccess: () => {
      resetAuth();
      removeCookie('rememberMe');
      navigate('/login');
    }
  });

  const handleLogout = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
    logoutQuery.mutate();
  };

  return <Link ref={forwardedRef} {...props} onClick={handleLogout}>{children}</Link>;
};

export default forwardRef((props, ref) => <LogoutLink {...props} forwardedRef={ref} />);
