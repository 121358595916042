import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import TagManager from 'react-gtm-module';

const trackPages = [
  '/signup/*',
  '/thank-you',
  '/verify-email',
  '/choose-subdomain',
  '/complete-account'
];

const useGtm = () => {
  const { pathname }  = useLocation();

  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID;

    if (gtmId && trackPages.find((path) => matchPath({ path }, pathname))) {
      TagManager.initialize({
        gtmId
      });
    }
  }, [pathname]);
};

export default useGtm;
