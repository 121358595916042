import moment from 'moment';
import { Trans } from 'react-i18next';

export const dateDiff = (startTime, endTime, interval = 'minutes') => {
  const duration = moment(endTime, 'YYYY-MM-DD[T]HH:mm:ss[Z]').diff(moment(startTime, 'YYYY-MM-DD[T]HH:mm:ss[Z]'), interval);
  if (duration > 0) {
    return Math.round(duration);
  }
  return 0;
};

export const duration = (startTime, endTime = null) => {
  const seconds = endTime ? dateDiff(startTime, endTime, 'seconds') : (startTime || 0);
  const minutes = Math.round((seconds % 3600) / 60);
  const hours   = Math.floor(seconds / 3600);

  if (seconds < 60) {
    return <Trans i18nKey="seconds" values={{ count: seconds }} />;
  }

  if (hours) {
    return minutes ? (
      <Trans
        i18nKey="hours_and_minutes"
        values={{
          hours, count: minutes
        }}
      />
    ) : <Trans i18nKey="hours" values={{ count: hours }} />;
  }

  return <Trans i18nKey="minutes" values={{ count: minutes }} />;
};

export const dateTime = (date) => {
  return moment(date).utc().format('ll HH:mm:ss');
};

export const date = (date) => {
  return moment(date).format(date.length >= 10 ? 'll' : 'MMM YYYY');
};

export const getDateRange = (v, team = null) => {
  switch (v) {
    case 'thisPeriod':
      return {
        dateStart: moment(team.subscription_start).format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'last30Days':
      return {
        dateStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'thisMonth':
      return {
        dateStart: moment().startOf('month').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'lastMonth':
      return {
        dateStart: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        dateEnd: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      };
    case 'last3Days':
      return {
        dateStart: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'last7Days':
      return {
        dateStart: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'thisWeek':
      return {
        dateStart: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    case 'lastWeek':
      return {
        dateStart: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        dateEnd: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
      };
    case 'yesterday':
      return {
        dateStart: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().subtract(1, 'days').format('YYYY-MM-DD')
      };
    case 'today':
      return {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
    default:
      return {
        dateStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD')
      };
  }
};
