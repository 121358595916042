import { Outlet } from 'react-router';
import { Box, Stack, Container } from '@mui/material';
import { useElementPosition } from 'hooks';
import Header from 'components/Layouts/Components/Header';
import Footer from 'components/Layouts/Components/Footer';
import SideMenu from 'components/Layouts/Components/Menus/SideMenu';
import Buttons from 'components/Layouts/Components/Buttons';

const StickyLayout = () => {
  const [ref, { top }] = useElementPosition();

  return (
    <Stack
      className="AppMainLayout"
      direction="column"
      sx={{
        minHeight: '100%',
        minWidth: 680
      }}
    >
      <Header className="StickyBox" />
      <Box ref={ref} display="flex" flex={1}>
        <Box
          className={!!top && 'StickyBox'}
          mr={{ md: 2, lg: 4, xl: 5 }}
          sx={{
            top,
            zIndex: 999
          }}
        >
          <SideMenu />
          <Buttons />
        </Box>
        <Container
          className="App-content"
          maxWidth={false}
        >
          <Outlet />
        </Container>
      </Box>
      <Footer />
    </Stack>
  );
};

export default StickyLayout;
