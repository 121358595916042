import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  useParams, useNavigate, useLocation, Navigate, Outlet
} from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { checkInvitationToken } from 'queries/teams';
import { useAuth } from 'hooks';

const VerifyToken = () => {
  const navigate      = useNavigate();
  const { pathname }  = useLocation();
  const { token }     = useParams();
  const { t }         = useTranslation();
  const {
    auth: {
      id:             isLogged,
      email_verified: isVerified,
      teams
    }
  } = useAuth();

  const { isSuccess, isError, data } = useQuery({
    queryKey: ['invitationToken', token],
    queryFn: () => checkInvitationToken(token)
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('messages.invitationLinkInvalid'), { variant: 'error' });
      navigate(isLogged ? '/' : '/login');
    }
  }, [isError, isLogged, navigate, t]);

  if (isLogged && !isVerified) {
    return <Navigate to="/verify-email" />;
  }

  if (isSuccess) {
    if (isLogged && pathname.indexOf('/join') === -1) {
      if (teams.find((team) => team.id === data.id)) {
        return <Navigate to={`/team/${data.id}`} />;
      }
      return <Navigate to={`/join/${token}`} />;
    }
    return (
      <Outlet context={{
        teamId: data.id,
        domain: data.domain,
        token
      }}
      />
    );
  }
};

export default VerifyToken;
