import i18n from 'i18next';

export const teamDomain = (team = '') => {
  const domain = team?.domain ?? team;
  return `${domain}.${process.env.REACT_APP_BASE_DOMAIN}`;
};

export const teamName = (team) => {
  return team.custom_cname || teamDomain(team);
};

export const teamUrl = (team, path) => {
  const domain = team.custom_cname || `${team.domain}.${process.env.REACT_APP_BASE_DOMAIN}`;
  return `https://${domain}/${path}`;
};

export const roomUrl = (team, room, token = null) => {
  const url = teamUrl(team, room?.friendly_url ?? room);

  return token ? `${url}?token=${token}` : url;
};

export const isPrivateRoom = (room) => {
  return room.privacy === 'private';
};

export const canJoinRoom = (room) => {
  return !room.room_is_deleted;
};

export const isConfidentialDataAllowed = (user, team) => {
  if (user.id === team.owner_id) {
    return true;
  }
  if (!team.confidentiality || !team.confidentiality.length) {
    return false;
  }
  return team.confidentiality.includes(team.role);
};

export const isDevelopment = () => {
  return process.env.REACT_APP_ENV !== 'production';
};

export const memberRole = (member, team = null) => {
  const role = member.role.toLowerCase();
  if (team && member.id === team.owner_id) {
    return i18n.t('owner');
  }
  return i18n.t(role);
};

export const isUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};

export const camalize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getPlans = (paidOnly = false) => {
  const plans = ['create', 'launch', 'grow', 'scale', 'thrive', 'expand'];
  if (paidOnly) {
    return plans;
  }
  return ['free', ...plans];
};

export const hasPlan = (team, requiredPlan) => {
  const plans = getPlans();

  const currentPlan = team.plan.name.toLowerCase().split(' ').shift();

  return plans.indexOf(currentPlan) >= plans.indexOf(requiredPlan);
};
