import { Navigate, useLocation } from 'react-router';
import { useAuth } from 'hooks';

const CurrentTeam = () => {
  const {
    auth: {
      last_team: team
    }
  } = useAuth();

  const { pathname } = useLocation();

  if (team) {
    return <Navigate to={`/teams/${team.id}${pathname}`} />;
  }
  return <Navigate to="/" />;
};

export default CurrentTeam;
