export default {
  cameraAndMicBlocked: 'Deine Kamera und dein Mikrofon sind blockiert. Du musst den Zugriff erlauben, um diese Systemprüfung durchzuführen. Klicke <0>hier</0>, um zu erfahren, wie du deine Geräte aktivierst.',
  signallingServerCheck: 'Signalisierungsserver-Prüfung',
  mediaServerCheck: 'Medienserver-API-Prüfung',
  connectionSuccessful: 'Verbindung zu IP {{ ip }} auf Port {{ port }} war erfolgreich.',
  connectionFailed: 'Verbindung zu IP {{ ip }} auf Port {{ port }} ist fehlgeschlagen.',
  connectingToServer: 'Verbinde mit {{ ip }} auf Port {{ port }}.',
  browserCheck: 'Browserprüfung',
  browserCheckRecommended: 'Du verwendest Google Chrome, der empfohlene Browser für die beste Erfahrung.',
  browserCheckSupported: 'Dein Browser wird unterstützt, aber wir empfehlen Google Chrome für die beste Erfahrung.',
  browserName: 'Browsername',
  browserVersion: 'Browserversion',
  platform: 'Plattform',
  userAgent: 'User-Agent',
  video: 'Video',
  audio: 'Audio',
  localVideo: 'Lokales Video',
  videoFromServer: 'Dein Video vom Server',
  recordingCountdown: 'Die Aufnahme wird in {{ countdown }} Sekunde(n) gestoppt.',
  playingRecording: 'Aufnahme wird abgespielt...',
  playRecording: 'Aufnahme abspielen',
  testMicrophone: 'Mikrofon testen',
  testMicrophoneInstructions: 'Sprich und mache eine Pause – du solltest deine Stimme hören.',
  testMicrophoneTooltip: 'Falls du deine Aufnahme nicht hörst, überprüfe dein Mikrofon.',
  speakers: 'Lautsprecher',
  defaultSpeakerDevice: 'Standardlautsprecher des Systems',
  playing: 'Wird abgespielt...',
  testSpeakers: 'Lautsprecher testen',
  testSpeakersInstructions: 'Du solltest einen Klingelton hören.',
  testSpeakersTooltip: 'Falls du den Ton nicht hörst, überprüfe deine Lautsprecher.',
  nextSteps: 'Wichtige nächste Schritte',
  nextStepsDescription: 'Auch wenn dein Test erfolgreich war, beachte bitte die folgenden Details und besuche dann unsere Seite zur Netzwerkkonfiguration <0>hier</0>.',
  webServer: 'Webserver',
  signalingServer: 'Signalisierungsserver',
  mediaServer: 'Medienserver',
  mediaServerApi: 'Medienserver-API',
  mediaTraffic: 'Medienverkehr',
  mediaTrafficOptions: [
    'OPTION 1 (Beste Leistung)',
    'OPTION 2 (Über einen TURN/Relay-Server)'
  ],
  httpTrafficPort: 'Erlaube HTTPS-Datenverkehr auf Port <0>{{ port }}</0>.',
  wssTrafficPort: 'Sichere Websockets/WSS-Datenverkehr auf Port <0>{{ port }}</0>.',
  mediaTrafficPort: 'HTTPS-Datenverkehr auf Port <0>{{ port }}</0>.',
  rtpTrafficPort: 'Erlaube RTP-Datenverkehr (TCP/UDP) auf Port',
  turnServer: 'TURN-Server',
  systemCheck: 'Systemprüfung',
  systemCheckWelcome: 'Willkommen zur Systemprüfung',
  systemCheckWelcomeMessage: 'Auf dieser Seite kannst du überprüfen, ob dein System bereit ist, sich mit der Konferenz zu verbinden.',
  systemCheckWelcomeStart: 'Systemprüfung starten',
  networkSettings: 'Netzwerk-Firewall oder Proxy-Server-Einstellungen',
  networkSettingsNote: 'HINWEIS: Alle Signalisierungs- und Medienserver-Endpunkte können durch das Zulassen der Wildcard-Domain *.wbcnf.net auf der Firewall freigegeben werden, falls dies von der Client-Firewall unterstützt wird.',
  ip: 'IP',
  ips: 'IPs',
  uri: 'URI'
};
