export default {
  free: {
    name: 'Free',
    description: 'Explore Digital Samba and start integrating our video API & SDK right away.',
    restinctions: [
      '25,000 participation minutes',
      'Logo branding',
      'AI captions'
    ],
    options: [
      '10,000 participation minutes',
      '100 concurrent participants',
      '100 concurrent participants per session',
      'Unlimited concurrent sessions',
      'Cloud recordings',
      'Whiteboard',
      'Colour branding',
      'Group chat',
      'Screen share',
      'Hand raise',
      'Waiting room',
      'E2E-encryption',
      'Mobile compatibility',
      'HD quality',
      '60 cloud recordings minutes',
      '60 cloud storage minutes'
    ]
  },
  create: {
    term: 1,
    price: '€99',
    large: {
      name: 'Create',
      description: 'An ideal choice for individuals looking to integrate video call functionality into event platforms or webinar software solutions.',
      options: [
        'All Free plan features',
        '25,000 participation minutes',
        '100 concurrent participants',
        '100 concurrent participants per session',
        '5 concurrent sessions',
        'Logo branding',
        'AI captions',
        ['Cloud recordings minutes', '€0.0130'],
        ['Cloud storage minutes', '€0.0030'],
        ['Extra participation minutes', '€0.0040'],
        ['Transcription minutes', '€0.0230']
      ]
    },
    small: {
      name: 'Create S',
      description: 'Best suited for hosting numerous simultaneous one-on-one calls within an application.',
      options: [
        'All Free plan features',
        '25,000 participation minutes',
        '40 concurrent participants',
        '2 concurrent participants per session',
        '20 concurrent sessions',
        'Logo branding',
        'AI captions',
        ['Cloud recordings minutes', '€0.0130'],
        ['Cloud storage minutes', '€0.0030'],
        ['Extra participation minutes', '€0.0040'],
        ['Transcription minutes', '€0.0230']
      ]
    }
  },
  launch: {
    term: 12,
    price: '€372',
    large: {
      name: 'Launch',
      description: 'Ideal for large-scale video conferences, accommodating up to 200 participants and 100 video broadcasters.',
      options: [
        'All Create plan features',
        '100,000 participation minutes',
        '200 concurrent participants',
        '200 concurrent participants per session',
        '10 concurrent sessions',
        'CNAME / White labelling',
        ['Cloud recordings minutes', '€0.0121'],
        ['Cloud storage minutes', '€0.0028'],
        ['Extra participation minutes', '€0.0037'],
        ['Transcription minutes', '€0.0214']
      ]
    },
    small: {
      name: 'Launch S',
      description: 'Designed for conducting multiple small video calls, with each session hosting up to three participants.',
      options: [
        'All Create S plan features',
        '100,000 participation minutes',
        '300 concurrent participants',
        '3 concurrent participants per session',
        '100 concurrent sessions',
        'CNAME / White labelling',
        ['Cloud recordings minutes', '€0.0121'],
        ['Cloud storage minutes', '€0.0028'],
        ['Extra participation minutes', '€0.0037'],
        ['Transcription minutes', '€0.0214']
      ]
    }
  },
  grow: {
    term: 12,
    price: '€1,700',
    large: {
      name: 'Grow',
      description: 'Optimal for orchestrating multiple extensive video conferences, supporting up to 500 attendees.',
      options: [
        'All Launch plan features',
        '500,000 participation minutes',
        '500 concurrent participants',
        '500 concurrent participants per session',
        '25 concurrent sessions',
        'Private Slack channel / Premium support',
        'Input into roadmap',
        'Full HD quality',
        'CNAME / White labelling',
        ['Cloud recordings minutes', '€0.0111'],
        ['Cloud storage minutes', '€0.0026'],
        ['Extra participation minutes', '€0.0034'],
        ['Transcription minutes', '€0.0196']
      ]
    },
    small: {
      name: 'Grow S',
      description: 'A prime choice for facilitating hundreds of smaller video conferences simultaneously, each with a maximum of four participants.',
      options: [
        'All Launch S plan features',
        '500,000 participation minutes',
        '800 concurrent participants',
        '4 concurrent participants per session',
        '200 concurrent sessions',
        'Private Slack channel / Premium support',
        'Input into roadmap',
        'Full HD quality',
        ['Cloud recordings minutes', '€0.0111'],
        ['Cloud storage minutes', '€0.0026'],
        ['Extra participation minutes', '€0.0034'],
        ['Transcription minutes', '€0.0196']
      ]
    }
  },
  scale: {
    term: 12,
    price: '€3,000',
    large: {
      name: 'Scale',
      description: 'Geared towards large-scale video calls, this plan supports events with up to 2000 attendees.',
      options: [
        'All Grow plan features',
        '1,000,000 participation minutes',
        '2,000 concurrent participants',
        '2,000 concurrent participants per session',
        '100 concurrent sessions',
        'Dedicated account manager',
        ['Cloud recordings minutes', '€0.0098'],
        ['Cloud storage minutes', '€0.0023'],
        ['Extra participation minutes', '€0.0030'],
        ['Transcription minutes', '€0.0173']
      ]
    },
    small: {
      name: 'Scale S',
      description: 'Excellent for substantial projects, capable of hosting up to 300 small meetings concurrently.',
      options: [
        'All Grow S plan features',
        '1,000,000 participation minutes',
        '1,200 concurrent participants',
        '4 concurrent participants per session',
        '300 concurrent sessions',
        'Dedicated account manager',
        ['Cloud recordings minutes', '€0.0098'],
        ['Cloud storage minutes', '€0.0023'],
        ['Extra participation minutes', '€0.0030'],
        ['Transcription minutes', '€0.0173']
      ]
    }
  },
  thrive: {
    term: 12,
    price: '€14,000',
    large: {
      name: 'Thrive',
      description: 'Break free from limits and choose the Thrive plan to enjoy 5,000,000 participation minutes per month, unlimited concurrent sessions, and over 2,000 concurrent users.',
      options: [
        'All Scale plan features',
        '5,000,000 participation minutes',
        ['Concurrent participants', 'Talk to us'],
        '2,000 concurrent participants per session',
        'Unlimited concurrent sessions',
        '100 concurrent video broadcasters per session',
        ['Cloud recordings minutes', '€0.0091 '],
        ['Cloud storage minutes', '€0.0021'],
        ['Extra participation minutes', '€0.0028'],
        ['Transcription minutes', '€0.0161']
      ]
    }
  },
  expand: {
    term: 12,
    price: '€26,000',
    large: {
      name: 'Expand',
      description: 'With 10,000,000 minutes per month and all our extras, you\'ve reached full potential.',
      options: [
        'All Thrive plan features',
        '10,000,000 participation minutes',
        ['Concurrent participants', 'Talk to us'],
        '2,000 concurrent participants per session',
        'Unlimited concurrent sessions',
        '100 concurrent video broadcasters per session',
        ['Cloud recordings minutes', '€0.0085'],
        ['Cloud storage minutes', '€0.0020'],
        ['Extra participation minutes', '€0.0026'],
        ['Transcription minutes', '€0.0151']
      ]
    }
  }
};
