import { Navigate, useLocation, Outlet } from 'react-router';
import { useAuth } from 'hooks';

const Auth = () => {
  const { pathname }  = useLocation();
  const {
    auth: {
      id:                  isLogged,
      onboarding_finished: isActive,
      email_verified:      isVerified,
      teams
    }
  } = useAuth();

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: pathname }} />;
  }

  if (!isVerified && !isActive && (pathname.indexOf('/verify-email') === -1 && pathname.indexOf('/thank-you') === -1)) {
    return <Navigate to="/verify-email" />;
  }

  if (isVerified) {
    if (!teams.length && pathname.indexOf('/choose-subdomain') === -1) {
      return <Navigate to="/choose-subdomain" />;
    }
    if (teams.length && !isActive && pathname.indexOf('/complete-account') === -1) {
      return <Navigate to="/complete-account" />;
    }
  }

  return <Outlet />;
};

export default Auth;
