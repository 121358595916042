import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEn from 'i18n/locales/en/common';
import productsEn from 'i18n/locales/en/products';
import systemcheckEn from 'i18n/locales/en/systemcheck';
import systemcheckDe from 'i18n/locales/de/systemcheck';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      common: commonEn,
      products: productsEn,
      systemcheck: systemcheckEn
    },
    'de-DE': {
      systemcheck: systemcheckDe
    }
  },
  ns: ['common'],
  defaultNS: 'common',
  languages: ['en'],
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
  interpolation: { escapeValue: false },
  useSuspense: false
});

i18n.services.formatter.add('lowercase', (value) => {
  return value.toLowerCase();
});

i18n.services.formatter.add('uppercase', (value) => {
  return value.toUpperCase();
});

i18n.services.formatter.add('capitalize', (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

export default i18n;
